/* ==========================================================================
   Variables
   ========================================================================== */

/* Breakpoint widths */
$small: 320px !default;
$medium: 768px !default;
$large: 1024px !default;
$xlarge: 1280px !default;

/* Fluid type */
$base-font-size: 16px !default;
$min-vw: $small !default;
$max-vw: $xlarge !default;
$min-font-size: 16px !default;
$max-font-size: 18px !default;

/* Calculate Modular Scale */
$modular-scale-1: 1.067 !default; /* small */
$modular-scale-2: 1.2 !default; /* large */
/* Heading 1 */
$h1-min: $modular-scale-1 * $modular-scale-1 * $modular-scale-1 * $modular-scale-1 * $base-font-size !default;
$h1-max: $modular-scale-2 * $modular-scale-2 * $modular-scale-2 * $modular-scale-2 * $base-font-size !default;
/* Heading 2 */
$h2-min: $modular-scale-1 * $modular-scale-1 * $modular-scale-1 * $base-font-size !default;
$h2-max: $modular-scale-2 * $modular-scale-2 * $modular-scale-2 * $base-font-size !default;
/* Heading 3 */
$h3-min: $modular-scale-1 * $modular-scale-1 * $base-font-size !default;
$h3-max: $modular-scale-2 * $modular-scale-2 * $base-font-size !default;
/* Heading 4 */
$h4-min: $modular-scale-1 * $base-font-size !default;
$h4-max: $modular-scale-2 * $base-font-size !default;
/* Heading 5 */
$h5-min: $base-font-size !default;
$h5-max: $base-font-size !default;
/* Heading 6 */
$h6-min: ($base-font-size / $modular-scale-1) !default;
$h6-max: ($base-font-size / $modular-scale-2) !default;

/* Font Families */
$serif-font-family: "Lora", serif !default;
$sans-serif-font-family: "Source Sans Pro", sans-serif !default;
$monospace-font-family: Menlo, Consolas, Monaco, "Courier New", Courier, monospace !default;

$base-font-family: $sans-serif-font-family !default;
$headline-font-family: $sans-serif-font-family !default;
$title-font-family: $serif-font-family !default;
$description-font-family: $serif-font-family !default;
$meta-font-family: $serif-font-family !default;

$page-title-weight: bold !default;
$entry-title-weight: bold !default;

/* YIQ color contrast */
$yiq-contrasted-dark-default: #000 !default;
$yiq-contrasted-light-default: #fff !default;
$yiq-contrasted-threshold: 175 !default;
$yiq-debug: false !default;

/* Brands */
$behance-color: #1769ff !default;
$bitbucket-color: #205081 !default;
$dribbble-color: #ea4c89 !default;
$facebook-color: #3b5998 !default;
$flickr-color: #ff0084 !default;
$foursquare-color: #0072b1 !default;
$github-color: #171516 !default;
$google-plus-color: #dd4b39 !default;
$instagram-color: #517fa4 !default;
$lastfm-color: #d51007 !default;
$linkedin-color: #007bb6 !default;
$pinterest-color: #cb2027 !default;
$reddit-color: #ff4500 !default;
$rss-color: #fa9b39 !default;
$soundcloud-color: #ff3300 !default;
$stackoverflow-color: #fe7a15 !default;
$tumblr-color: #32506d !default;
$twitter-color: #55acee !default;
$vimeo-color: #1ab7ea !default;
$vine-color: #00bf8f !default;
$youtube-color: #bb0000 !default;
$xing-color: #006567 !default;

/* Max-width of the main content */
$main-max-width: $medium !default;

/* Max-width of the main content + sidebar */
$main-sidebar-max-width: $large !default;

/* Site logo */
$site-logo-width: 100px !default;
$site-logo-height: 100px !default;

/* Border radius */
$border-radius: 0.25rem !default;

/* Global transition */
$global-transition: all 0.4s ease !default;
